const theme = {
  fontFamily: '"Lato", sans-serif',
  fontSize: '16px',
  colors: {
    primary: '#2f75f1',
    primaryDark: '#162b5b',
    black: '#000000',
    bermudaGray: '#6f83ac',
    black: '#131317',
  },
}

export default theme
